<template>
  <page>
    <router-view></router-view>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'

export default {
  name: 'Index.vue',
  components: { Page },
}
</script>

<style scoped></style>
